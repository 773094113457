import { computed } from "vue";
import { emailRegex } from "@utils/regex";

export default function useValidate() {
  function emainValidate(email: string) {
    return computed(() => {
      if (email) return false;
      return emailRegex.test(email);
    });
  }

  return { emainValidate };
}
