<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import XInput from "@ui/XInput.vue";
import XHeader from "@ui/XHeader.vue";
import XButton from "@ui/XButton.vue";
import XFormBorder from "@ui/XFormBorder.vue";
import { storeToRefs } from "pinia";
import XError from "@ui/XError.vue";
import XTransitionFadeIn from "@ui/XTransitionFadeIn.vue";
import { useErrorStore } from "@store/useErrorStore";
import { API_URL } from "@config/core";
import useValidate from "@composables/useValidate";

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
const { setRegisterError } = errorStore;

const isSubmitDisabled = ref(false);
const showSuccessMessage = ref(false);

const form = reactive({
  email: "",
  password: {
    password: "",
    confirm_password: "",
  },
});

const { emainValidate } = useValidate();
const formEmailIsValid = emainValidate(form.email);

async function handleSubmit() {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
      credentials: "include" as RequestCredentials,
    };

    isSubmitDisabled.value = true;

    // @ts-ignore
    const res = await fetch(`${API_URL}/register`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.log("error", error));

    if (res[0] === "OK") {
      setRegisterError(false);
      showSuccessMessage.value = true;
    } else {
      setRegisterError(true, res);
      isSubmitDisabled.value = false;
      showSuccessMessage.value = false;
    }
  } catch (error) {
    console.error(error);
  }
}

onMounted(() => {
  showSuccessMessage.value = false;
});
</script>

<template>
  <XHeader title="Реєстрація" />

  <XTransitionFadeIn v-if="error?.register?.value">
    <XError v-for="message in error.register?.message" class="error-message">{{
      message
    }}</XError>
  </XTransitionFadeIn>

  <XFormBorder>
    <div v-if="!showSuccessMessage">
      Станом на зараз активація зарєєстрованих користувачів здійснюється
      адміністрацією сайту
    </div>

    <div v-else>
      <b>Вітаємо ви зарєструвались!</b> Тепер треба почекати на активацію
      адміністрацією
    </div>

    <br />

    <XInput
      v-model:value="form.email"
      name="email"
      type="email"
      :label="
        formEmailIsValid ? 'Електронна пошта &check;' : 'Електронна пошта'
      "
    />

    <XInput
      v-model:value="form.password.password"
      name="password"
      type="password"
      label="Пароль"
    />

    <XInput
      v-model:value="form.password.confirm_password"
      name="confirmPassword"
      type="password"
      label="Ще раз пароль"
    />

    <XButton
      :disabled="
        !(
          form.email?.length > 0 &&
          form.password.password?.length > 0 &&
          form.password.password?.length ===
            form.password.confirm_password?.length
        ) || isSubmitDisabled
      "
      title="Зареєструватись"
      @click.prevent="handleSubmit"
    />
  </XFormBorder>
</template>

<style scoped></style>
