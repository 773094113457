<script setup lang="ts">
import XButton from "@ui/XButton.vue";
import XInput from "@ui/XInput.vue";
import { reactive, ref } from "vue";
import useWaitingList from "@composables/useWaitingList";
import useValidate from "@composables/useValidate";

const waitingListData = reactive({
  name: "",
  email: "",
});

const { emainValidate } = useValidate();
const emailIsValid = emainValidate(waitingListData.email);

const isDisabled = ref(false);

const isResponseOk = ref();

const { handleSubmitForWaitingList } = useWaitingList();

async function handleForm() {
  isDisabled.value = true;

  const res = await handleSubmitForWaitingList(waitingListData);

  // validate email on input
  waitingListData.name = "";
  waitingListData.email = "";

  if (res?.ok) {
    isResponseOk.value = true;
    isDisabled.value = false;
  } else {
    isResponseOk.value = false;
    isDisabled.value = false;
  }
}
</script>

<template>
  <div class="waiting-list-block">
    <div v-if="isResponseOk" class="waiting-list-block__message">
      Вітаємо! Вас додано до списку очікування
    </div>

    <h4 class="waiting-list-block__category" id="how-to-becoma-a-user">
      Як стати користувачем
    </h4>

    <div class="block-layout block-layout--row">
      <section class="waiting-list-block__standard">
        Лишіть своє імʼя та електронну пошту і ми запросимо поступово всіх, хто
        виявив бажання почати користуватись сервісом.
      </section>

      <aside class="waiting-list-block__form" style="flex: 1">
        <XInput
          v-model:value="waitingListData.name"
          name="name"
          type="text"
          label="Імʼя"
          :disabled="isDisabled"
          style="color: white"
        />

        <XInput
          v-model:value="waitingListData.email"
          name="email"
          type="text"
          :label="
            emailIsValid ? 'Електронна пошта &check;' : 'Електронна пошта'
          "
          :disabled="isDisabled"
          style="color: white"
        />

        <XButton
          title="Увійти"
          :disabled="
            isDisabled || !emailIsValid || waitingListData?.name?.length <= 2
          "
          @click="handleForm"
        />
      </aside>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "src/scss/vars" as *;

.waiting-list-block {
  position: relative;
  background-color: #465f58;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05),
    0 17px 15px -1rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @include screen($lg) {
    flex-direction: column;
    column-gap: 1rem;
    padding: 2rem;
  }
}

.waiting-list-block__message {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  border-radius: 0.6rem;
  height: 1.2rem;
  width: fit-content;
  background-color: $light-violet;
  padding: 0 1rem;
  font-size: 0.84rem;
  font-weight: bold;
  line-height: 1.2rem;
}

.waiting-list-block__category {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  line-height: 1.4;
  color: #aba18d;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.waiting-list-block__standard {
  font-family: $font-family-serif;
  color: $white;
  line-height: 1.5;

  @include screen($md) {
    width: 50%;
  }
}

.block-layout {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.block-layout--row {
  @include screen($md) {
    flex-direction: row;
    column-gap: 2rem;
  }
}
</style>
