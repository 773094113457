<script setup lang="ts">
import { useAuthStore } from "@store/useAuthStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import XTransitionMenu from "@ui/XTransitionMenu.vue";

const store = useAuthStore();
const { setAuth } = store;
const { isAuth } = storeToRefs(store);
const router = useRouter();

function handleLogout() {
  setAuth(false);
  router.push("/");

  return;
}
</script>

<template>
  <XTransitionMenu>
    <div class="top-menu">
      <RouterLink to="/" class="top-menu__item">Головна</RouterLink>
      <!-- <RouterLink to="/prices" class="top-menu__item">Ціни</RouterLink> -->
      <RouterLink v-if="!isAuth" to="/sign-up" class="top-menu__item"
        >Реєстрація</RouterLink
      >
      <RouterLink v-if="!isAuth" to="/sign-in" class="top-menu__item"
        >Авторизація</RouterLink
      >
      <RouterLink v-if="isAuth" to="/user/sessions" class="top-menu__item"
        >Сеанси</RouterLink
      >
      <button
        v-if="isAuth"
        @click.prevent="handleLogout"
        class="top-menu__logout"
      >
        вихід
      </button>
    </div>
  </XTransitionMenu>
</template>

<style lang="scss" scoped>
@use "src/scss/vars" as *;

.top-menu {
  padding: 0 1rem;
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  font-weight: bold;

  @include screen($lg) {
    margin: 0 auto;
  }

  &__item {
    padding: 1rem 0;
  }

  &__logout {
    margin-left: auto;
    padding: 0 0.5rem;
    height: 1.4rem;
    box-sizing: border-box;
    margin-top: 1rem;
    background-color: transparent;
    color: $dark-beige;
    border: 1px solid $dark-beige;
    font-weight: bold;
    border-radius: 1rem;
    cursor: pointer;
    transition: all 100ms ease-in-out;

    &:hover {
      color: $brown;
      border: 1px solid $brown;
      transition: all 100ms ease-in-out;
    }
  }
}
</style>
