import { API_URL } from "@config/core";

type WaitingListData = {
  name: string;
  email: string;
};

export default function useWaitingList() {
  async function handleSubmitForWaitingList(data: WaitingListData) {
    try {
      const res = await fetch(`${API_URL}/registration-request`, {
        method: "POST",
        body: JSON.stringify(data),
        redirect: "follow",
        credentials: "include",
      });

      return res;
    } catch (error) {
      console.error(error);
    }
    return;
  }
  return {
    handleSubmitForWaitingList,
  };
}
